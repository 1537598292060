import { Injectable, Predicate, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Airline,
  CatalogueGetFlightDateOptionsListParams,
  City,
  Country,
  Hotel,
  HotelServiceEnum,
  HotelTypeEnum
} from '@appTypes/api.types';
import { UnsafeValue } from '@appTypes/common.types';
import { DistrictsTreeItem } from '@appTypes/redefined-types';
import { greaterThanOrEquals, lessThanOrEquals, minNumbers } from '@core/misc/validators.utils';
import { RequestStateModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { SVGIcon } from '@data/svg-icons.data';
import { environment } from '@env/environment';
import { UpdateFormValue } from '@ngxs/form-plugin';
import {
  STATIC_OPTIONS_HOTEL_CLASSES_STARS,
  STATIC_OPTIONS_HOTEL_MEAL_PLANS,
  STATIC_OPTONS_HOTEL_SERVICES,
  STATIC_OPTONS_HOTEL_TYPES
} from '@pages/_common/static-data.const';
import { ToursBookingInstructionsComponent } from '@pages/tours-page/desktop/tours-search-desktop-form/tours-booking-instructions/tours-booking-instructions.component';
import {
  toursGetArrivalDistricts,
  toursGetAvailableAirlines,
  toursGetDestinationCountries,
  toursGetHotels,
  toursLoadFlightDateOptions,
  toursResetSearchResult,
  toursSearchTourPackages
} from '@pages/tours-page/state/tours.actions';
import { ToursState } from '@pages/tours-page/state/tours.state';
import {
  NGXS_TOURS_SEARCH_FORM,
  TOUR_DIRECT_FLIGHT_CHECKBOX_HIDE_FOR_ARRIVAL_DIRECTIONS
} from '@pages/tours-page/tours-page.const';
import {
  DynamicDateClassItem,
  DynamicDateClassLoadFn
} from '@shared/abstract-classes/date-picker-dynamic-data-class.abstract';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { addDays, differenceInDays } from 'date-fns';
import { Observable, combineLatest, filter, map, take, tap } from 'rxjs';
import {
  appGetDepartureCities,
  appSetCurrency,
  appSetSearchByUrlModule,
  appSetSellCurrency
} from 'src/app/state/app.actions';
import { AppPersistedState } from '../../../state/app.persisted.state';
import { AppState } from '../../../state/app.state';

@Injectable()
export class ToursSearchFormService {
  public today = new Date();
  public isSubmited = false;

  public readonly ngxsForm = NGXS_TOURS_SEARCH_FORM;

  public formStatus$ = this.storeService.select(ToursState.searchLoadStatus);

  public readonly formGroup = new FormGroup({
    departureCountryCode: new FormControl<string | null>(
      this.storeService.selectSnapshot(AppPersistedState.activedCountry)
    ),
    departureCityUID: new FormControl<string | null>(
      this.storeService.selectSnapshot(AppPersistedState.defaultDepartureCityUID)
    ),
    airlineCode: new FormControl<string | null>(null),
    arrivalCountryCode: new FormControl<string | null>(
      this.storeService.selectSnapshot(AppPersistedState.activedDestinationCountry),
      [Validators.required]
    ),
    adults: new FormControl<number>(2),
    children: new FormControl<number>(0),
    childrenAges: new FormArray<FormControl<number | null>>([]),

    departureDate: new FormControl<Date>(this.today, [Validators.required]),
    returnDate: new FormControl<Date>(addDays(this.today, 7), [Validators.required]),
    departureAndReturnDaysCount: new FormControl<number>(7, [
      Validators.required,
      Validators.min(0),
      Validators.max(30)
    ]),
    checkInDate: new FormControl<Date>(this.today, [Validators.required]),
    checkOutDate: new FormControl<Date>(addDays(this.today, 7), [Validators.required]),
    checkInAndCheckOutDaysCount: new FormControl<number>(7, [
      Validators.required,
      minNumbers(1, `Number of nights must be greater than {{minNumbers}}`),
      Validators.max(30)
    ]),
    directFlightsOnly: new FormControl<boolean | null>(true),
    longConnectTime: new FormControl<boolean | null>(null),
    extendedSearch: new FormControl<boolean | null>(null),
    freeSale: new FormControl<boolean | null>(null),
    groupByHotel: new FormControl<boolean | null>(null),

    arrivalLocations: new FormControl<DistrictsTreeItem[] | null>(null),
    hotelCodes: new FormControl<string[] | null>(null),

    totalPriceFrom: new FormControl<number | null>(null, [lessThanOrEquals('totalPriceTo')]),
    totalPriceTo: new FormControl<number | null>(null, [greaterThanOrEquals('totalPriceFrom')]),
    hotelTypes: new FormControl<HotelTypeEnum[] | null>(null), //HotelTypeEnum[] | null;
    hotelClasses: new FormControl<string[] | null>(null),
    hotelServices: new FormControl<HotelServiceEnum[] | null>(null),

    mealPlans: new FormControl<string[] | null>(null),

    districtUIDs: new FormControl(), //??
    onlyAvailableRooms: new FormControl<boolean | null>(null),
    searchCurrency: new FormControl<string | null>(null)
  });
  private storeArrivalCountryCode: string | null = null;

  public pages = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: -1, label: 'All' }
  ];

  get childrenAges() {
    return this.formGroup.controls.childrenAges.controls;
  }

  public get hasChildrenAgesError() {
    if (!this.formGroup.controls.childrenAges.controls.length) return false;
    return this.formGroup.controls.childrenAges.controls.some((e) => e.errors);
  }
  public childrenAgesData = Array.from(Array(environment.childMaxAge).keys()).map((i) => ({
    value: i + 1,
    label: `${i + 1}`
  }));

  private _changeCheckInCheckOutDatesToggle = false;
  public get changeCheckInCheckOutDatesToggle() {
    return this._changeCheckInCheckOutDatesToggle;
  }
  public set changeCheckInCheckOutDatesToggle(state: boolean) {
    this._changeCheckInCheckOutDatesToggle = state;
    this.toggleCheckOutCheckInControls();
  }

  get hasLimitErrorOnDepartureAndReturnDaysCount() {
    return Boolean(this.formGroup.controls.departureAndReturnDaysCount.errors?.['max']);
  }
  get hasLimitErrorOndepartureCheckInAndCheckOutDaysCount() {
    return Boolean(this.formGroup.controls.checkInAndCheckOutDaysCount.errors?.['max']);
  }

  public countriesTypedToken!: Country & { icon: SVGIcon };
  public countries$ = this.storeService.select(ToursState.destinationCountriesWithFlagIcon);
  public countriesExtraOptions: (Country & { icon: SVGIcon })[] = [
    { countryName: 'Other Countries', countryCode: null, icon: 'map-marker' }
  ];

  public departureCities$ = this.storeService.select(AppState.departureCities);
  public citiesExtraOptions: City[] = [{ cityUID: null as UnsafeValue<string>, cityName: 'All departure cities' }];

  public airlinesTypedToken!: Airline;
  public airlines$ = this.storeService.select(ToursState.availableAirlinesWithIcons);
  public airlinesExtraOptions: (Airline & { icon: SVGIcon })[] = [
    { airlineCode: null, airlineName: 'All Airlines', icon: 'airline-logo-all' }
  ];

  public arrivalDistrictsTypedToken!: DistrictsTreeItem;
  public arrivalDistricts$ = this.storeService.select(ToursState.arrivalDistricts);
  public availableHotelsTypedToken!: Hotel;
  public availableHotels$ = this.storeService.select(ToursState.hotels);

  public mealPlans = STATIC_OPTIONS_HOTEL_MEAL_PLANS;
  public hotelClasses = STATIC_OPTIONS_HOTEL_CLASSES_STARS;
  public hotelServices = STATIC_OPTONS_HOTEL_SERVICES;
  public hotelTypes = STATIC_OPTONS_HOTEL_TYPES;

  private isInit = false;
  public isReady = false;

  public pageRowCount = signal<number>(25);

  public hideDirectFlightsOnly = false;

  public searchByUrlModule = false;

  hotelsOptionFilterPredicate: Predicate<Hotel> | null = null;

  constructor(
    private storeService: StoreService,
    private dynamicModalService: DynamicModalService
  ) {}

  public init() {
    if (this.isInit) return;
    this.initSearchData();
    this.toggleCheckOutCheckInControls();
    this.isInit = true;
  }
  public onSubmit() {
    if (!this.isSubmited) this.isSubmited = true;
    if (this.formGroup.invalid) return;
    this.pageRowCount.set(25);
    this.storeService.dispatch(new toursSearchTourPackages());
  }
  public selectedCityData(city: City | null) {
    if (city && city?.countryCode) this.formGroup.controls.departureCountryCode.setValue(city.countryCode);
  }

  public onAddChildren(children: number) {
    const current = this.formGroup.controls.childrenAges.length;
    if (current < children)
      for (let index = 0; index < children - current; index++)
        this.formGroup.controls.childrenAges.push(new FormControl<number | null>(null, Validators.required));
    else if (current > children)
      for (let index = children; index < current; index++) this.formGroup.controls.childrenAges.removeAt(index);
  }

  public updateHotelFilters(isInitialRequest = false) {
    const { hotelClasses, hotelTypes, arrivalLocations } = this.formGroup.value;

    const cityIds = arrivalLocations?.filter((el) => el.type === 'city').map((el) => el.value);
    const districtIds = arrivalLocations?.filter((el) => el.type === 'district').map((el) => el.value);
    if (!isInitialRequest) this.formGroup.controls.hotelCodes.setValue(null);

    if (!hotelClasses && !hotelTypes && !arrivalLocations && !cityIds && !districtIds) {
      if (this.hotelsOptionFilterPredicate) this.hotelsOptionFilterPredicate = null;
      return;
    }

    this.hotelsOptionFilterPredicate = (hotel: Hotel) => {
      return (
        (!hotelClasses?.length || hotelClasses.includes(hotel.hotelClass as string)) &&
        (!hotelTypes?.length || hotelTypes.includes(hotel.hotelType as HotelTypeEnum)) &&
        (!cityIds?.length || cityIds.includes(hotel.cityUID)) &&
        (!districtIds?.length || districtIds.includes(hotel.districtUID))
      );
    };
  }

  private initSearchData() {
    const storedFormData = this.storeService.selectSnapshot(ToursState.searchFormData);
    if (storedFormData) this.formGroup.patchValue({ ...(storedFormData as typeof this.formGroup.value) });

    const formData = this.formGroup.getRawValue();
    if (storedFormData?.childrenAges?.length)
      for (const iterator of storedFormData.childrenAges)
        this.formGroup.controls.childrenAges.push(new FormControl<number | null>(iterator, Validators.required));
    this.storeService.dispatch([
      new toursGetDestinationCountries(),
      new appGetDepartureCities({ countryCode: this.storeService.selectSnapshot(AppPersistedState.activedCountry) }), //@ToDo - Wich country must be set?
      new toursGetAvailableAirlines({
        departureCityUid: formData?.departureCityUID ?? undefined,
        destinationCountryCode: formData?.arrivalCountryCode ?? undefined
      }),
      new toursGetArrivalDistricts(
        {
          countryCode: formData?.arrivalCountryCode ?? undefined
        },
        true
      ),
      new toursGetHotels(
        {
          countryCode: formData?.arrivalCountryCode ?? undefined
        },
        true
      )
    ]);

    this.searchByUrlModule = this.storeService.selectSnapshot(AppState.searchByUrlModule) === 'tours';

    this.observeMandatoryOptions().subscribe((isReady) => {
      this.isReady = isReady;
      if (!isReady) return;

      // //update hotel filters
      this.calcDepartureAndReturnDaysCount();
      this.calcCheckInAndCheckOutDaysCount();
      this.updateHotelFilters(true);

      //Submit while SearchByUrl module exists
      if (this.searchByUrlModule) {
        if (this.formGroup.controls.searchCurrency.value) {
          const currencyExists = this.storeService
            .selectSnapshot(AppState.currencies)
            .some((e) => e.code === this.formGroup.controls.searchCurrency.value);
          if (currencyExists)
            this.storeService.dispatch(new appSetCurrency(this.formGroup.controls.searchCurrency.value));
        }
        setTimeout(() => {
          this.searchByUrlModule = false;
          this.storeService.dispatch(new appSetSearchByUrlModule(null));
          this.onSubmit();
        }, 100);
      }
    });
  }
  private observeMandatoryOptions() {
    const mandatoryOptions: Observable<RequestStateModel>[] = [
      this.countries$,
      this.departureCities$,
      this.airlines$,
      this.arrivalDistricts$,
      this.availableHotels$
    ];
    return combineLatest(
      mandatoryOptions.map((e) =>
        e.pipe(
          filter((e) => e.loadState.status === 'completed'),
          map((e) => e.loadState.status === 'completed'),
          take(1)
        )
      )
    ).pipe(map((result) => result.every((value) => value)));
  }
  public onDepartureCityChange(departureCityUid?: string) {
    //reset selected airline
    this.formGroup.controls.airlineCode.setValue(null);

    this.storeService.dispatch(
      new toursGetAvailableAirlines(
        {
          departureCityUid,
          destinationCountryCode: this.formGroup?.controls?.arrivalCountryCode?.value ?? undefined
        },
        true
      )
    );
  }
  public onArrivalCountryChange(country: Country) {
    if (!country?.sellCurrency) return;

    this.storeService.dispatch(new appSetSellCurrency('tours', country.sellCurrency));
    if (!this.isReady) return;

    let countryCode = country?.countryCode;

    //reset selected cities & hotels
    if (!countryCode) {
      window.open('https://online.kazunion.com/search_tour', '_blank');
      countryCode =
        this.storeArrivalCountryCode ?? this.storeService.selectSnapshot(AppPersistedState.activedDestinationCountry);
      this.formGroup.controls.arrivalCountryCode.setValue(countryCode);
      return;
    }
    //hide direct flights only for specific arrival directions task: #REQ-10240
    if (TOUR_DIRECT_FLIGHT_CHECKBOX_HIDE_FOR_ARRIVAL_DIRECTIONS.includes(countryCode)) {
      this.hideDirectFlightsOnly = true;
      this.formGroup.controls.directFlightsOnly.setValue(null);
    } else if (this.hideDirectFlightsOnly) this.hideDirectFlightsOnly = false;

    this.storeArrivalCountryCode = countryCode ?? null;

    this.formGroup.controls.arrivalLocations.setValue(null);
    this.formGroup.controls.hotelCodes.setValue(null);
    this.hotelsOptionFilterPredicate = null;

    this.storeService.dispatch([
      new toursGetArrivalDistricts(
        {
          countryCode
        },
        true
      ),
      new toursGetHotels(
        {
          countryCode
        },
        true
      )
    ]);
    this.storeService.dispatch(
      new toursGetAvailableAirlines(
        {
          departureCityUid: this.formGroup.controls.departureCityUID.value ?? undefined,
          destinationCountryCode: countryCode
        },
        true
      )
    );
    this.clearSearchFormFilterControls();
  }
  private clearSearchFormFilterControls() {
    this.formGroup.patchValue({
      mealPlans: null,
      hotelClasses: null,
      hotelTypes: null,
      hotelServices: null
    });
  }

  public dateClassLoadFn$: DynamicDateClassLoadFn<{ isFrom: boolean }> = ({
    month,
    year,
    context
  }): Observable<DynamicDateClassItem[]> => {
    const {
      airlineCode: airlineId,
      departureCityUID: fromCityId,
      arrivalCountryCode: toCountryId
    } = this.formGroup.getRawValue();

    const payload = {
      AirlineCode: airlineId,
      CityUID: fromCityId,
      CountryCode: toCountryId,
      IsFrom: context?.isFrom,
      Month: month,
      Year: year
    } as CatalogueGetFlightDateOptionsListParams;

    const identifier = window.btoa(JSON.stringify(payload));

    return this.storeService
      .dispatchRequestModel$(
        new toursLoadFlightDateOptions(payload, identifier),
        ToursState.flightAvailabilityByParams(identifier)
      )
      .pipe(
        map((e) => {
          return (
            e?.response?.availableDates?.map((el) => ({
              date: el.departureDate as Date,
              cssClass: this.getFlightAvailabilityClass(el.remainingTickets)
            })) ?? []
          );
        })
      );
  };

  private getFlightAvailabilityClass(availableCount?: number) {
    if (availableCount && availableCount > environment.defaultFlightAvailabilityCount) return ['flight-available'];
    else if (availableCount && availableCount > 0 && availableCount <= environment.defaultFlightAvailabilityCount)
      return ['flight-fever'];
    else if (availableCount === 0) return ['flight-frequency'];
    return [''];
  }
  public calcDepartureAndReturnDaysCount(isDepartureDateChange = false) {
    const departureDate = this.formGroup.controls.departureDate.value;
    let returnDate = this.formGroup.controls.returnDate.value;
    const departureAndReturnDaysCount = this.formGroup.controls.departureAndReturnDaysCount.value;

    if (
      departureDate &&
      isDepartureDateChange &&
      departureAndReturnDaysCount !== null &&
      departureAndReturnDaysCount > -1
    ) {
      returnDate = addDays(departureDate, departureAndReturnDaysCount);
      this.formGroup.controls.returnDate.setValue(returnDate);
    } else {
      const difference = departureDate && returnDate ? differenceInDays(returnDate, departureDate) : null;
      if (difference && difference < 0 && departureDate) {
        returnDate = addDays(departureDate, 7);
        this.formGroup.controls.returnDate.setValue(returnDate);
      }
      this.formGroup.controls.departureAndReturnDaysCount.setValue(
        departureDate && returnDate ? differenceInDays(returnDate, departureDate) : null
      );
    }

    this.updateCheckOutCheckInDatesIfAble();
  }
  public updateDatesByDepartureAndArrivalDaysCount() {
    const departureAndReturnDaysCount = this.formGroup.controls.departureAndReturnDaysCount.value;
    if (departureAndReturnDaysCount === null || departureAndReturnDaysCount < 0) return;

    let departureDate = this.formGroup.controls.departureDate.value;
    if (!departureDate) {
      departureDate = new Date();
      this.formGroup.controls.departureDate.setValue(departureDate);
    }

    this.formGroup.controls.returnDate.setValue(addDays(departureDate, departureAndReturnDaysCount));

    this.updateCheckOutCheckInDatesIfAble();
  }
  public calcCheckInAndCheckOutDaysCount(isCheckInDateChange = false) {
    const checkInDate = this.formGroup.controls.checkInDate.value;
    let checkOutDate = this.formGroup.controls.checkOutDate.value;
    const checkInAndCheckOutDaysCount = this.formGroup.controls.checkInAndCheckOutDaysCount.value;

    if (
      checkInDate &&
      isCheckInDateChange &&
      checkInAndCheckOutDaysCount !== null &&
      checkInAndCheckOutDaysCount > -1
    ) {
      checkOutDate = addDays(checkInDate, checkInAndCheckOutDaysCount);
      this.formGroup.controls.checkOutDate.setValue(checkOutDate);
    } else {
      const difference = checkInDate && checkOutDate ? differenceInDays(checkOutDate, checkInDate) : null;
      if (difference && difference < 0 && checkInDate) {
        checkOutDate = addDays(checkInDate, 7);
        this.formGroup.controls.checkOutDate.setValue(checkOutDate);
      }
      this.formGroup.controls.checkInAndCheckOutDaysCount.setValue(
        checkInDate && checkOutDate ? differenceInDays(checkOutDate, checkInDate) : null
      );
    }
  }

  public updateCheckInAndCheckOutDaysCount() {
    const checkInAndCheckOutDaysCount = this.formGroup.controls.checkInAndCheckOutDaysCount.value;
    if (checkInAndCheckOutDaysCount === null || checkInAndCheckOutDaysCount < 0) return;

    let checkInDate = this.formGroup.controls.checkInDate.value;
    if (!checkInDate) {
      checkInDate = new Date();
      this.formGroup.controls.checkInDate.setValue(checkInDate);
    }

    this.formGroup.controls.checkOutDate.setValue(addDays(checkInDate, checkInAndCheckOutDaysCount));
  }
  /*  */
  public toggleCheckOutCheckInControls() {
    if (this.changeCheckInCheckOutDatesToggle) {
      this.formGroup.controls.checkInDate.enable();
      this.formGroup.controls.checkOutDate.enable();
      this.formGroup.controls.checkInAndCheckOutDaysCount.enable();
    } else {
      this.formGroup.controls.checkInDate.disable();
      this.formGroup.controls.checkOutDate.disable();
      this.formGroup.controls.checkInAndCheckOutDaysCount.disable();
      this.updateCheckOutCheckInDatesIfAble();
    }
  }
  public updateCheckOutCheckInDatesIfAble() {
    if (!this.changeCheckInCheckOutDatesToggle) {
      this.formGroup.controls.checkInDate.setValue(this.formGroup.controls.departureDate.value ?? null);
      this.formGroup.controls.checkOutDate.setValue(this.formGroup.controls.returnDate.value);
    }
  }

  public openInstructions() {
    this.dynamicModalService.open({
      title: 'Instructions for booking in the new online',
      component: ToursBookingInstructionsComponent,
      width: '620px'
    });
  }

  public resetForm() {
    this.formGroup.reset({
      adults: 1,
      children: 0,
      childrenAges: [],
      departureCityUID: this.storeService.selectSnapshot(AppPersistedState.defaultDepartureCityUID),
      arrivalCountryCode: this.storeService.selectSnapshot(AppPersistedState.activedDestinationCountry),
      departureDate: this.today,
      returnDate: addDays(this.today, 7),
      departureAndReturnDaysCount: 7,
      directFlightsOnly: true,
      checkInDate: this.today,
      checkOutDate: addDays(this.today, 7),
      checkInAndCheckOutDaysCount: 7
    });
    this.storeService.dispatch(new UpdateFormValue({ path: this.ngxsForm, value: this.formGroup.getRawValue() }));
    setTimeout(() => {
      this.storeService.dispatch(new toursResetSearchResult());
    }, 100);
    this.changeCheckInCheckOutDatesToggle = false;
    this.isSubmited = false;
  }
}
